<template>
    <div id="float-agent">
        <img src="@/assets/float-agent/13.png" v-on:click="showAgent()" style="z-index: 900;">
    </div>
</template>

<script setup>
import emitter from "./UniqEvent";
import {onMounted} from "vue";

function showAgent() {
    emitter.emit('show-agent');
}

onMounted(() => {
    let ua = navigator.userAgent;
    if (!ua.includes('iPhone') && !ua.includes('Android')) {
        let node = document.querySelector('#float-agent');
        node.style.setProperty('--right', 'calc(50vw - 17.5rem)');
        node.style.setProperty('--width', '5rem');
    }
});
</script>

<style scoped>

#float-agent {
    --right: 0.5rem;
    --width: 4rem;
    position: fixed;
    right: var(--right);
    top: calc(50% + 2rem);
    z-index: 5000;
}

#float-agent img {
    width: var(--width);
}
</style>